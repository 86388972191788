import throttle from 'OK/utils/throttle';
import { parent as getParent } from 'OK/utils/dom';
import { getNodeDebugInfo } from 'OK/utils/debug';

var PARENT_CSS_CLASS = 'js-viewport-container',
    itemsContainers = [],
    activated = false,
    resizeListener = throttle(100, function () {
        itemsContainers.forEach(function (container) {
            container.calculate();
        });
    });

/**
 * @param element
 * @returns {ItemsContainer}
 */
function findContainerByElement(element) {
    var result = null;
    itemsContainers.some(function (container) {
        if (container.element === element) {
            result = container;
            return true;
        }
    });
    return result;
}

function removeFromArray(array, element) {
    var index = array.indexOf(element);
    if (index > -1) {
        array.splice(index, 1);
    }
}

class Item {
    /**
     * @param {HTMLElement} element
     * @param {ItemsContainer} container
     * @param {Function} callback
     * @param {Function} predicate
     */
    constructor(element, container, callback, predicate) {
        this.element = element;
        this.container = container;
        this.callback = callback;
        this.predicate = predicate ? predicate.bind(this) : null;
    }

    destroy() {
        this.container = null;
    }

    isInViewport() {
        var bounds = this.container.rect, viewportTop = bounds.top, viewportBottom = bounds.bottom, itemBounds;

        try {
            itemBounds = this.element.getBoundingClientRect();
        } catch (e) {
            itemBounds = {top: 0, right: 0, bottom: 0, left: 0, width: 0, height: 0};
        }

        return (itemBounds.top >= viewportTop && itemBounds.top <= viewportBottom) ||
            (itemBounds.bottom >= viewportTop && itemBounds.bottom <= viewportBottom);
    }

    calculate() {
        var inViewport = this.predicate ? this.predicate() : this.isInViewport();
        if (this.visible === inViewport) {
            return;
        }
        this.visible = inViewport;
        this.callback.call(this.element, inViewport);
    }
}


class ItemsContainer {
    /**
     * @param {HTMLElement} element
     *
     * @property {HTMLElement} element
     * @property {Item[]} items
     * @property {Function} listener
     * @property {ClientRect} rect
     */
    constructor(element) {
        this.element = element;
        this.items = [];
        this.listener = throttle(100, this.calculate.bind(this));
        this.element.addEventListener('scroll', this.listener);
        this.updateRect();
    }

    add(item) {
        this.items.push(item);
        this.updateRect();
        item.calculate();
    }

    updateRect() {
        this.rect = this.element.getBoundingClientRect();
    }

    remove(element) {
        var itemByElement = null;
        this.items.forEach(function (item) {
            if (item.element === element) {
                itemByElement = item;
                return true;
            }
        });
        if (itemByElement) {
            itemByElement.destroy();
            removeFromArray(this.items, itemByElement);
        }
    }

    isEmpty() {
        return this.items.length === 0;
    }

    isLast(item) {
        return this.items[this.items.length - 1] === item;
    }

    calculate() {
        this.updateRect();
        this.items.forEach(function (item) {
            item.calculate();
        });
    }

    destroy() {
        this.element.removeEventListener('scroll', this.listener);
    }
}


function getPolyfillScriptInfo(scriptId) {
    var script = document.getElementById(scriptId);
    var info = scriptId + ': ' + Boolean(script);
    if (script) {
        info += script.getAttribute('data-loaded') === 'true' ? '; loaded' : '; not loaded';
    }
    return {
        info: info,
        src: script
            ? script.src
            : ''
    };
}

function add(element, callback, predicate) {
    var parent = getParent(element, PARENT_CSS_CLASS),
        container;

    if (parent === null) {
        parent = window;
    }

    if (typeof parent.getBoundingClientRect !== 'function') {
        if (parent === window) {
            var polyfillsInfo = getPolyfillScriptInfo('polyfills-script');
            var polyfillsModernInfo = getPolyfillScriptInfo('polyfills-modern-script');
            OK.Tracer.log('ViewportTracker. element: ' + getNodeDebugInfo(element, {children: true})
                + '\npolyfills src: ' + polyfillsInfo.src
                + '\npolyfillsModern src: ' + polyfillsModernInfo.src
            );
            OK.Tracer.error(
                new Error('ViewportTracker: window.getBoundingClientRect is not function'),
                {
                    getBoundingClientRect: parent.getBoundingClientRect,
                    polyfills: polyfillsInfo.info,
                    polyfillsModern: polyfillsModernInfo.info
                }
            );
        } else {
            OK.Tracer.error(
                new Error('ViewportTracker: parent.getBoundingClientRect is not function'),
                {
                    element: getNodeDebugInfo(element, {children: true}),
                    getBoundingClientRect: parent.getBoundingClientRect,
                    parent: getNodeDebugInfo(parent, {children: true})
                }
            );
        }
        parent = document.body;
    }

    container = findContainerByElement(parent);
    if (!container) {
        container = new ItemsContainer(parent);
        itemsContainers.push(container);
    }
    container.add(new Item(element, container, callback, predicate));

    if (!activated) {
        window.addEventListener('resize', resizeListener);
        activated = true;
    }
}

function remove(element) {
    var parent = getParent(element, PARENT_CSS_CLASS),
        container;

    if (parent === null) {
        parent = window;
    }

    container = findContainerByElement(parent);
    if (container) {
        container.remove(element);
        if (container.isEmpty()) {
            container.destroy();
            removeFromArray(itemsContainers, container);
        }
    }

    if (activated && itemsContainers.length === 0) {
        window.removeEventListener('resize', resizeListener);
        activated = false;
    }
}

function getContainer(element) {
    var parent = getParent(element, PARENT_CSS_CLASS);

    return findContainerByElement(parent);
}

export default { add, remove, getContainer };

export { add, remove, getContainer };
